export const domain = `skolanalys.se`
export const apiBase = `https://api.grundskola.${domain}/api`
export const grundskolaUrl = `https://grundskola.${domain}`
export const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_4_ID

export const authUrls = {
  issuer: process.env.REACT_APP_ISSUER,
  jwks_uri: process.env.REACT_APP_JWKS_URI,
  authorization_endpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
  token_endpoint: process.env.REACT_APP_TOKEN_ENDPOINT,
  userinfo_endpoint: process.env.REACT_APP_USERINFO_ENDPOINT,
  end_session_endpoint: process.env.REACT_APP_END_SESSION_ENDPOINT,
  check_session_iframe: process.env.REACT_APP_CHECK_SESSION_IFRAME,
  revocation_endpoint: process.env.REACT_APP_REVOCATION_ENDPOINT,
  introspection_endpoint: process.env.REACT_APP_INTROSPECTION_ENDPOINT,
  device_authorization_endpoint:
    process.env.REACT_APP_DEVICE_AUTHORIZATION_ENDPOINT,

  send_reset_password_email_endpoint:
    process.env.REACT_APP_SEND_RESET_PASSWORD_EMAIL_ENDPOINT,
  change_password_endpoint: process.env.REACT_APP_CHANGE_PASSWORD_ENDPOINT,
  activate_account_endpoint: process.env.REACT_APP_ACTIVATE_ACCOUNT_ENDPOINT,
}
