import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/fonts/fonts.css'
import './index.css'
import reportWebVitals from './reportWebVitals'
import Spinner from './components/misc/spinner'
import { googleAnalyticsId } from './services/config'
import GA4React from 'ga-4-react'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18next'
import enTranslation from './locales/en.json'
import svTranslation from './locales/sv.json'

const App = React.lazy(() => import('./App'))

const googleAnalytics4 = new GA4React(googleAnalyticsId)

;(async () => {
  try {
    await googleAnalytics4.initialize()
  } catch (error) {
    console.error('Google analytics initialization error:', error)
  }
  await i18n.init({
    interpolation: { escapeValue: false },
    lng: 'se', // Default language
    fallbackLng: 'se', // Fallback language
    resources: {
      en: {
        translation: enTranslation,
      },
      se: {
        translation: svTranslation,
      },
    },
  })

  ReactDOM.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <Suspense
          fallback={
            <div className="h-full w-full flex flex-row items-center justify-center fixed left-0 top-0 bottom-0 right-0">
              <Spinner />
            </div>
          }
        >
          <App />
        </Suspense>
      </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
