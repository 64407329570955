import React from 'react'
import SpinnerImg from '../../assets/spinner.gif'

const Spinner = () => {
  return (
    <div className="spinner">
      <img src={SpinnerImg} alt="" width="48" height="48" />
    </div>
  )
}

export default Spinner
